import './bootstrap';
import '../css/app.css';
import '../css/vue-select.css';
import 'flowbite';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createApp, h } from 'vue/dist/vue.esm-bundler.js';
import { createInertiaApp, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js';
import { initializeApp } from 'firebase/app';
import VNetworkGraph from "v-network-graph";
import "v-network-graph/lib/style.css";
import * as Sentry from "@sentry/vue";
import { createPinia } from 'pinia';
import { useUserActivityStore } from "@/stores/userActivityStore.js";
import { useAppStore } from "@/stores/appStore.js";
import { useGalleryStore} from "@/stores/galleryStore.js";
import 'material-design-icons-iconfont/dist/material-design-icons.css';

const pinia = createPinia();

const firebaseConfig = {
    apiKey: "AIzaSyACAM_mb5hzOZVt3BfGSaHVGTCR1XV8Qik",
    authDomain: "blackwidow-cms-demo.firebaseapp.com",
    projectId: "blackwidow-cms-demo",
    storageBucket: "blackwidow-cms-demo.appspot.com",
    messagingSenderId: "365408920007",
    appId: "1:365408920007:web:7d91725e32354a5d8c39e2"
};

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


const vuetify = createVuetify({
    components,
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    directives,
})

initializeApp(firebaseConfig);
const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    runtimeCompiler: true,
    transpileDependencies: true,
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(Toast)
            .use(pinia)
            .use(vuetify)
            .use(VNetworkGraph)
            .use(ZiggyVue)
            .component('inertia-link', Link);

        const navigationStore = useUserActivityStore(pinia);
        const appStore = useAppStore(pinia);
        const galleryStore = useGalleryStore(pinia);

        galleryStore.start();
        // Load version data initially
        appStore.getVersion().then(() => {
            console.log("Version Loaded");
        });

        document.addEventListener('inertia:navigate', (event) => {
            const ip_address = event.detail.page.props.ip_address;
            const user_agent = event.detail.page.props.user_agent;
            navigationStore.addEvent({
                type: 'view',
                timestamp: Date.now(),
                url: event.detail.page.url,
                data: {
                    ip_address: ip_address,
                    user_agent: user_agent,
                    url: event.detail.page.url,
                },
            });
        });

        return vueApp.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
